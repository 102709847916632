main[pagename="task"] {
    .formio-component-datagrid.scoring-errors-table {
       table.datagrid-table{
        thead{
            tr{
                th{
                    text-align: left;

                    &:first-child{
                        width: 120px;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    .formio-component-textfield{
                        max-width: unset;
                    }

                    .disabledValueContainer{
                        text-align: left
                    }
                }
            }
        }
       }
    }
}
