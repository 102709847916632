:root {
        /* Header */
        --header-background: #010101;
        --header-text-color: #ffffff;
        --header-active-link-color: #ffffff;
        --header-active-btn-bg: #222325;
        --header-font-size: 1.15rem;
        --user-menu-color-hover: #010101;
        --user-menu-bg: #f5f5f7;
    
        /*Body*/
        --background: #ffffff;
        --aside-list-item-bg: #ffffff;
        --aside-list-active-bg: #f1f1f1;
        --aside-list-active-border-color: #3FE579;
        --aside-list-border-color-hover: #1d1d1f;
        --btn-bg: #1d1d1f;
        --btn-bg-hover: #1d1d1f;
        --filter-button-bg: #f5f5f7;
        --filter-button-active-bg: #1d1d1f;
        --text-color: #1d1d1f;
    
        /*Form*/
        --form-text-color: #1d1d1f;
        --form-secondary-text-color: #1d1d1f;
        --label-color: #86868b;
        --header-panel-bg: #F5F5F7;
        --input-border-color: #dadee2;
        --input-border-color-hover: #dadee2;
        --input-border-color-focus: #9CE4A3;
        --input-bg: #ffffff;
        --selected-select-item: #f5f5f5;
        --toggle-switcher-active-bg: #ffffff;
        --toggle-switcher-border-active-color: #ffffff;
        --toggle-active-bg: #39F179;
        --toggle-switcher-bg: #dadee2;
        --toggle-switcher-border-color: #dadee2;
        --tab-color: #1d1d1f;
        --tab-bg: transparent;
        --tab-active-bg: #1d1d1f;
        --tab-active-color: #ffffff;
}