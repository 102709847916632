.btn {
    height: 2.6rem;
}

.col-form-label {
    font-size: $main-font-size;
    line-height: 125%;
    color: var(--label-color);
    padding: 0 0.24rem 0 0;
}

.input-group:has(> input.form-control) {
    .form-control {
        border-right: none;
        padding-right: 0;
    }
}

.formio-component-datetime {
    .input-group:has(> input.form-control) {
        .form-control {
            border-right: $input-border-width solid var(--input-border-color);
            border-radius: 0.35rem;
        }
    }
}

.form-check {
    padding-left: 0;
}

.formio-component-checkbox {
    margin-bottom: 0.6rem;
}

.form-check-label {
    position: relative;

    .form-check-input {
        position: relative;
        width: 0;
        height: 0;
        // opacity: 0;
        display: none;

        + span {
            font-size: $main-font-size;
            line-height: 125%;
            color: var(--form-text-color);
            display: flex;
            align-items: start;
            transition: all ease 0.2s;

            &::before {
                content: '';
                cursor: pointer;
                display: inline-block;
                width: 3rem;
                min-width: 3rem;
                height: 1.6rem;
                margin-right: 0.6rem;
                background-color: var(--input-bg);
                border-radius: 1.6rem;
                border: $input-border-width solid var(--input-border-color);
                left: 0;
                top: 0;
            }

            &::after {
                content: '';
                cursor: pointer;
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                position: absolute;
                background-color: var(--toggle-switcher-bg);
                border-radius: 1rem;
                border: $input-border-width solid var(--toggle-switcher-border-color);
                left: 0;
                top: 0;
                transform: translate(0.2rem, 0.15rem);
            }
        }

        &:checked {
            + span {
                &::before {
                    background-color: var(--toggle-active-bg);
                    border-color: var(--toggle-active-bg);
                }

                &::after {
                    left: 1.4rem;
                    background-color: var(--toggle-switcher-active-bg);
                    border-color: var(--toggle-switcher-border-active-color);
                }
            }
        }

        &:disabled {
            + span {
                &::before {
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
        }
    }

    &:hover {
        .form-check-input:not(:disabled) {
            + span {
                &::before {
                    border: $input-border-width solid var(--input-border-color-hover);
                }
            }
        }
    }
}

.bg-default {
    background: transparent;
}


.card {
    padding: 0;
    background-color: transparent;
    &.bg-light {
        background-color: transparent !important;
    }
}

.common-width {
    padding: 0 1.6rem;
}

.formio-component-idDocument {
    .formio-component-attachments {
        span {
            margin-bottom: 0.6rem;
        }
    }
}

.js-image-zoom {
    span {
        margin-bottom: 0.6rem;
    }
}

.text-align-right {
    text-align: right;
}

.text-align-right-input {
    input {
        text-align: right;
    }
}

.text-bold {
    font-weight: 600;
}

.text-bold-input {
    input {
        font-weight: 600;
    }
}

.choices.is-disabled .choices__input {
    display: none;
} 

.formio-component-multiple {
    .is-disabled {
        .choices__list--multiple { 
            .choices__item {
                padding: 0 0.12rem 0 0;
                background-color: transparent;
                border: none;
                color: var(--form-text-color);
                font-size: 1.4rem;
                span {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.65;
                    color: #0d0f1c;
                }
            }
            .choices__item::after {
                content: ',';
            }
            .choices__item:last-child::after {
                content: '';
            }
        }
    } 
}

.formio-choices {
    .choices__input {
        background-color: transparent;
    }
}

.invalid-feedback {
    font-size: 1rem;
}

.grid-loader-wrapper {
    width: 100%;
    min-height: 11.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .grid-loader {
        border: 0.12rem solid #f3f3f3;
        border-top: 0.12rem solid #0d0f1c;
        border-radius: 50%;
        width: 4.11rem;
        height: 4.11rem;
        animation: spin 2s linear infinite;
      }
}

.field-content > div {
    height: 100%;
    input:disabled {
        height: 100%;
    }
}

.field-wrapper {
    .field-label {
        display: flex;
        align-items: center;
    }
    .field-content > div {
        display: flex;
        align-items: center;
    }
}

.formio-component-url,
.formio-component-phoneNumber,
.formio-component-email,
.formio-component-address:not(.formio-component-container),
.formio-component-datetime,
.formio-component-number,
.formio-component-select,
.formio-component-textfield {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    
    .col-form-label {
        padding: 0;
        padding-right: 0.7rem;
    }
}

.formio-component-url.input-group-disabled,
.formio-component-phoneNumber.input-group-disabled,
.formio-component-email.input-group-disabled,
.formio-component-datetime.input-group-disabled,
.formio-component-number.input-group-disabled,
.formio-component-select.input-group-disabled,
.formio-component-textfield.input-group-disabled {
    align-items: flex-start;
}

.formio-component-email:not(.input-group-disabled),
.formio-component-phone:not(.input-group-disabled),
.formio-component-url:not(.input-group-disabled),
.formio-component-datetime:not(.input-group-disabled),
.formio-component-address:not(.input-group-disabled),
.formio-component-number:not(.input-group-disabled),
.formio-component-select:not(.input-group-disabled),
.formio-component-textfield:not(.input-group-disabled) {
    margin-bottom: $input-margin-bottom !important;
    max-width: $max-inputs-width;
}

.has-feedback {
    padding-right: 0;
}

.ant-btn-primary,
.btn-primary:disabled,
.btn-primary {
    transition: all ease 0.3s;
    border-radius: 0.45rem;
    background-color: var(--btn-bg);
    border-color: var(--btn-bg);
}

.btn-primary:hover {
    background-color: var(--btn-bg-hover) !important;
    border-color: var(--btn-bg-hover) !important;
}

.ant-btn-primary:hover {
    background-color: var(--btn-bg-hover) !important;
    border-color: var(--btn-bg-hover) !important;
}

.formio-component-html a,
.internal-link {
    display: block;
    font-size: $main-font-size;
    color: var(--form-text-color);
    text-decoration: underline;
    margin: 0 0 0.6rem 0;
}

.internal-link:hover {
    color: var(--form-text-color);
}

.ant-list-split.ant-list-something-after-last-item
.ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border: none;
}

.input-group-disabled.formio-component-url,
.input-group-disabled.formio-component-email,
.input-group-disabled.formio-component-phoneNumber,
.input-group-disabled.formio-component-datetime,
.input-group-disabled.formio-component-number,
.input-group-disabled.formio-component-select,
.input-group-disabled.formio-component-textfield {
    margin-bottom: $input-disabled-margin-bottom;
    max-width: $max-inputs-width;
    min-height: fit-content;
    .form-control {
        min-height: fit-content;
        height: 100%;
    }
    .col-form-label,
    .form-control {
        pointer-events: none;
    }
}

.form-group:not(.input-group-disabled) {
    margin-bottom: 0;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.disabledValueContainer {
    font-size: $main-font-size;
    color: var(--form-text-color);
    font-weight: 400;
    line-height: 125%;
    display: flex;
    align-items: center;
}

.btn-primary:focus {
    box-shadow: none;
    background: var(--btn-bg);
    border-color: var(--btn-bg);
}

.card-title {
    color: var(--text-color);
}

.file {
    .formio-errors.invalid-feedback {
        display: block !important;
    }
}

.break-word {
    word-break: break-word;
}

.sr-only {
    user-select: none;
}
